import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import DownloadLink from '../../webview/commons/components/DownloadLink.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks/index.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import {
	selectNumDocuments,
	selectPatientDetailsId,
	selectTotalNumFiles
} from '../../webview/patient-details/flux/selectors/PatientDetailsSelectors.js';
import Alert from '../../webview/ui/components/Alert.js';
import Button from '../../webview/ui/components/Button.js';
import DownloadIcon from '../../webview/ui/components/icons/DownloadIcon.js';
import {Body1} from '../../webview/ui/components/typography/Typography.js';

function DownloadAllButton(props) {
	const {numDocuments: numStudies, numFiles, patientId} = props;
	const [downloadInitiated, setDownloadInitiated] = useState(false);
	const onClickHandler = useCallback(() => setDownloadInitiated(true), [setDownloadInitiated]);
	const downloadButton = useMemoFactory(createDownloadButton, downloadInitiated, numFiles);
	return (
		<React.Fragment>
			<SynFormattedMessage element={Body1} message='DownloadAllDocumentsInfo' messageParams={{numStudies, numFiles}} sx={{my: 2}} />
			{downloadInitiated ? downloadButton : (
				<DownloadLink href={`/patient/${patientId}/documents.download`} onClick={onClickHandler}>
					{downloadButton}
				</DownloadLink>
			)}
			{downloadInitiated && (
				<Alert severity='info'>
					<SynFormattedMessage message='DownloadInProgressHint' />
				</Alert>
			)}
		</React.Fragment>
	);
}

DownloadAllButton.propTypes = {
	numDocuments: PropTypes.number,
	numFiles: PropTypes.number,
	patientId: PropTypes.number
};

function createDownloadButton(downloadInitiated, numFiles) {
	return (
		<Button startIcon={<DownloadIcon />} disabled={downloadInitiated} flat opaque>
			<SynFormattedMessage message='DownloadAllDocumentsButton' messageParams={{numFiles}} />
		</Button>
	);
}

export default connect(
	createStructuredSelector({
		numDocuments: selectNumDocuments,
		numFiles: selectTotalNumFiles,
		patientId: selectPatientDetailsId
	})
)(DownloadAllButton);
