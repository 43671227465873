import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '../../webview/commons/components/AppBar.js';
import AppBarOverflowButton from '../../webview/commons/components/AppBarOverflowButton.js';
import AppBarPrimaryContent from '../../webview/commons/components/AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../../webview/commons/components/webview/AppBarSidebarHeader.js';
import SessionPatientInfoContainer from '../containers/SessionPatientInfoContainer.js';

export default function PatientAppBar(props) {
	const {menuButton, sidebarElevated, children} = props;
	return (
		<AppBar>
			<AppBarPrimaryContent menuButton={menuButton}>
				<SessionPatientInfoContainer />
			</AppBarPrimaryContent>
			{children}
			<AppBarOverflowButton visible={sidebarElevated} />
			<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
		</AppBar>
	);
}

PatientAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired,
	menuButton: PropTypes.node
};
