import React from 'react';
import {styled} from '@mui/material/styles';

import BrickGuard from '../../../webview/bricks/components/BrickGuard.js';
import BrickProvider from '../../../webview/bricks/components/BrickProvider.js';
import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import {TranslationProvider} from '../../../webview/i18n/components/TranslationContext.js';
import DialogBody from '../../../webview/material-design/components/DialogBody.js';
import DialogHeader from '../../../webview/material-design/components/DialogHeader.js';
import StepperDialog from '../../../webview/material-design/components/StepperDialog.js';
import ShareDocumentDialogProxy from '../../../webview/share-document/components/ShareDocumentDialogProxy.js';
import {DEVICE_MEDIA_QUERY_UP_TO_MOBILE_SMALL} from '../../../webview/ui/constants/DeviceMediaQueries.js';
import CreateDocPermitWorkflow from '../bricks/CreateDocPermitWorkflow.js';
import useWorkflowBrick from '../hooks/useWorkflowBrick.js';
import DocumentPermissionMessagesTranslator from '../i18n/DocPermitMessagesTranslator.js';
import CreateDocPermitStepper from './CreateDocPermitStepper.js';

const DIALOG_TITLE = <SynFormattedMessage message='CreatePermitDialogTitle' />;
const StyledStepperDialog = styled(StepperDialog)(buildStepperDialogStyle);

export default React.memo(CreateDocPermitDialog);

function CreateDocPermitDialog(props) {
	const {documentIds, onClose} = props;
	const workflowBrick = useWorkflowBrick(documentIds, onClose);
	return (
		<TranslationProvider translator={DocumentPermissionMessagesTranslator}>
			<BrickProvider brick={workflowBrick} />
			<BrickGuard brick={CreateDocPermitWorkflow}>
				<StyledStepperDialog onClose={onClose} className='create-doc-permit-dialog'>
					<DialogHeader title={DIALOG_TITLE} onClose={onClose} />
					<DialogBody>
						<CreateDocPermitStepper onFinished={onClose} />
					</DialogBody>
				</StyledStepperDialog>
			</BrickGuard>
		</TranslationProvider>
	);
}
CreateDocPermitDialog.propTypes = {
	...ShareDocumentDialogProxy.propTypes
};

function buildStepperDialogStyle() {
	return {
		'.material-dialog--content': {
			minWidth: '500px',
			minHeight: '500px',
			maxWidth: '500px'
		},
		[DEVICE_MEDIA_QUERY_UP_TO_MOBILE_SMALL]: {
			'.material-dialog--content': {
				minWidth: '0px',
				minHeight: '0px',
				maxWidth: 'none'
			}
		}
	};
}
