import React from 'react';
import PropTypes from 'prop-types';

import BrickGuard from '../../webview/bricks/components/BrickGuard';
import {TranslationProvider} from '../../webview/i18n/components/TranslationContext';
import UploadTranslator from '../../webview/upload/i18n/UploadTranslator';
import WebPatientUploadController from '../bricks/WebPatientUploadController';
import WebPatientUploadAppBar from './WebPatientUploadAppBar';

export default function WebPatientUploadAppBarController(props) {
	const {sidebarElevated} = props;
	return (
		<BrickGuard brick={WebPatientUploadController}>
			<TranslationProvider translator={UploadTranslator}>
				<WebPatientUploadAppBar sidebarElevated={sidebarElevated} />
			</TranslationProvider>
		</BrickGuard>
	);
}

WebPatientUploadAppBarController.propTypes = {
	sidebarElevated: PropTypes.bool
};
