import React from 'react';

import {MobileInfoColumn} from '../../../../webupload/components/UploadFilesList/Columns.js';
import {useMemoFactory} from '../../../../webview/commons/utils/customHooks';
import {immutableMapPropType} from '../../../../webview/commons/utils/CustomPropTypes.js';
import NumericDate from '../../../../webview/i18n/components/NumericDate.js';
import HorizontalLayout from '../../../../webview/ui/components/layout/HorizontalLayout.js';
import Sticky from '../../../../webview/ui/components/layout/Sticky.js';
import {Body2} from '../../../../webview/ui/components/typography/Typography.js';
import DeletePermitButton from './DeletePermitButton.js';
import {COLUMN_STICKY_TOP, generateTanInformationPresentation, renderDocuments} from './DesktopPermitsListEntry.js';
import {HEADER_STYLE} from './DesktopPermitsListHeader.js';

const STICKY_ENTRY_STYLES = {top: COLUMN_STICKY_TOP, mb: 2, width: '100%', bgcolor: 'background.paper', zIndex: 'drawer'};

export default function MobilePermitsListEntry(props) {
	const {entry} = props;
	const receiver = entry.get('email');
	const tanInformation = useMemoFactory(generateTanInformationPresentation, entry.get('tan_information'));
	const renderedDocuments = useMemoFactory(renderDocuments, entry.get('documents'));
	return (
		<MobileInfoColumn style={{...HEADER_STYLE, width: '100%'}}>
			<HorizontalLayout element={Sticky} align='center' sx={STICKY_ENTRY_STYLES}>
				<Body2 flexGrow={1} style={{wordBreak: 'break-all'}}>
					{receiver}
				</Body2>
				<NumericDate element={Body2} pr={1} date={entry.get('valid_until')} />
				<Body2 flexShrink={0}>
					{tanInformation}
				</Body2>
			</HorizontalLayout>
			{renderedDocuments}
			<HorizontalLayout sx={{width: '100%'}} justify='end' mt={2}>
				<DeletePermitButton permitId={entry.get('id')} />
			</HorizontalLayout>
		</MobileInfoColumn>

	);
}

MobilePermitsListEntry.propTypes = {
	entry: immutableMapPropType
};
