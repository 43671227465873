const IMPL_PROPERTY = Symbol('ImageDecoderImpl');

export default class ImageDecoder {
	constructor(decodeFunction, releaseFunction, imageConsumer) {
		this[IMPL_PROPERTY] = new ImageDecoderImpl(decodeFunction, releaseFunction, imageConsumer);
	}

	scheduleDecoding(rawImage) {
		this[IMPL_PROPERTY].scheduleDecoding(rawImage);
	}

	stop() {
		this[IMPL_PROPERTY].stop();
	}
}

class ImageDecoderImpl {
	constructor(decodeFunction, releaseFunction, imageConsumer) {
		this.decodeFunction = decodeFunction;
		this.releaseFunction = releaseFunction;
		this.imageConsumer = imageConsumer;
		this.scheduledImage = null;
		this.decodedImage = null;
		this.isDecoding = false;
		this.keepDecoding = true;
	}

	scheduleDecoding(rawImage) {
		this.#setScheduledImage(rawImage);
		this.#startDecoding();
	}

	stop() {
		this.keepDecoding = false;
		this.#releaseDecodedImage();
	}

	#setScheduledImage(rawImage) {
		if (this.keepDecoding || !rawImage) {
			this.scheduledImage = rawImage;
		}
	}

	#startDecoding() {
		if (this.keepDecoding && !this.isDecoding) {
			this.isDecoding = true;
			this.#doDecode();
		}
	}

	async #doDecode() {
		if (this.keepDecoding) {
			const currentlyDecodingImage = this.scheduledImage;
			let decodedImage = null;
			try {
				if (currentlyDecodingImage) {
					decodedImage = await this.decodeFunction(currentlyDecodingImage);
				}
			} catch (e) {
				if (this.scheduledImage === currentlyDecodingImage) {
					// this should definitely never be the case, however if it happens, we
					// ensure that the exception will trigger an error event (instead of unhandled promise rejection)
					window.setTimeout(() => {
						throw new Error(`Error decoding image: ${e}`);
					});
				}
			}
			if (this.scheduledImage === currentlyDecodingImage) {
				this.#setDecodedImage(decodedImage);
			} else {
				await this.#doDecode();
			}
		} else {
			this.#setDecodedImage(null);
		}
	}

	#setDecodedImage(decodedImage) {
		this.isDecoding = false;

		const prevDecodedImage = this.decodedImage;
		this.decodedImage = null;
		if (this.keepDecoding) {
			this.decodedImage = decodedImage;
			this.imageConsumer(decodedImage);
		} else {
			this.#releaseImage(decodedImage);
		}
		if (prevDecodedImage !== decodedImage) {
			this.#releaseImage(prevDecodedImage);
		}
		this.#setScheduledImage(null);
	}

	#releaseDecodedImage() {
		if (this.decodedImage !== null) {
			this.#releaseImage(this.decodedImage);
			this.decodedImage = null;
		}
	}

	#releaseImage(image) {
		if (image) {
			this.releaseFunction(image);
		}
	}
}
