import React from 'react';
import Box from '@mui/material/Box';

export function UnorderedList(props) {
	const {sx: additionalStyles, ...remainingProps} = props;
	const sx = {
		listStyleType: 'none',
		m: 0,
		p: 0,
		...additionalStyles
	};
	return <Box component='ul' sx={sx} {...remainingProps} />;
}

UnorderedList.propTypes = Box.propTypes;
