import React from 'react';

import useBrick from '../../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../../webview/commons/bricks/DeviceInfo.js';
import ScrollView from '../../../../webview/commons/components/ScrollView.js';
import Divider from '../../../../webview/ui/components/Divider.js';
import Sticky from '../../../../webview/ui/components/layout/Sticky.js';
import DesktopPermitsListHeader from './DesktopPermitsListHeader.js';
import DocumentPermitsListBody from './DocumentPermitsListBody.js';
import MobilePermitsListHeader from './MobilePermitsListHeader.js';

export default function PermitsList(props) {
	const isMobileDevice = useBrick(DeviceInfo, device => device.isMobileDevice());
	const Header = isMobileDevice ? MobilePermitsListHeader : DesktopPermitsListHeader;
	return (
		<ScrollView>
			<Sticky sx={{zIndex: 'modal', bgcolor: 'background.paper'}}>
				<Header />
				<Divider />
			</Sticky>
			<DocumentPermitsListBody />
		</ScrollView>
	);
}
