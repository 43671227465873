import React from 'react';

import MaterialDataTableConstants from '../../../../constants/MaterialDataTableConstants.json';
import {
	DateColumn,
	DeleteColumn,
	EmailColumn,
	NameColumn,
	TanColumn
} from '../../../../webupload/components/UploadFilesList/Columns.js';
import DocumentSummary from '../../../../webview/commons/components/DocumentSummary';
import MaskedPassword from '../../../../webview/commons/components/text/MaskedPassword.js';
import {useMemoFactory} from '../../../../webview/commons/utils/customHooks';
import {immutableMapPropType} from '../../../../webview/commons/utils/CustomPropTypes.js';
import NumericDate from '../../../../webview/i18n/components/NumericDate.js';
import SynFormattedMessage from '../../../../webview/i18n/components/SynFormattedMessage.js';
import Sticky from '../../../../webview/ui/components/layout/Sticky.js';
import {UnorderedList} from '../../../../webview/ui/components/List/UnorderedList.js';
import {UnorderedListItem} from '../../../../webview/ui/components/List/UnorderedListItem.js';
import DeletePermitButton from './DeletePermitButton.js';
import {HEADER_STYLE, HEADER_VERTICAL_PADDING} from './DesktopPermitsListHeader.js';
import TanInformationDialogButton from './TanInformationDialogButton.js';

const HEADER_TEXT_HEIGHT = 15;
const HEADER_TOTAL_HEIGHT = 2 * HEADER_VERTICAL_PADDING + HEADER_TEXT_HEIGHT + 2;
export const COLUMN_STICKY_TOP = `${HEADER_TOTAL_HEIGHT}px`;

export default function DesktopPermitsListEntry(props) {
	const {entry} = props;
	const receiver = entry.get('email');
	const tanInformation = useMemoFactory(renderTanInformation, entry.get('tan_information'));
	const renderedDocuments = useMemoFactory(renderDocuments, entry.get('documents'));
	return (
		<React.Fragment>
			<NameColumn style={{...HEADER_STYLE, fontWeight: 700}}>
				{renderedDocuments}
			</NameColumn>
			<EmailColumn>
				<Sticky sx={{...HEADER_STYLE, top: COLUMN_STICKY_TOP, width: '100%', wordBreak: 'break-all'}}>
					{receiver}
				</Sticky>
			</EmailColumn>
			{tanInformation}
			<DateColumn>
				<Sticky sx={{...HEADER_STYLE, zIndex: 'drawer', top: COLUMN_STICKY_TOP}}>
					<NumericDate date={entry.get('valid_until')} />
				</Sticky>
			</DateColumn>
			<DeleteColumn style={{alignItems: 'flex-start', overflow: 'initial', width: '150px'}}>
				<Sticky sx={{zIndex: 'drawer', top: COLUMN_STICKY_TOP}}>
					<DeletePermitButton permitId={entry.get('id')} />
				</Sticky>
			</DeleteColumn>
		</React.Fragment>
	);
}

DesktopPermitsListEntry.entryHeightField = MaterialDataTableConstants['material-data-table']['row-min-height'];

DesktopPermitsListEntry.propTypes = {
	entry: immutableMapPropType
};

function renderTanInformation(tanInformation) {
	const style = ['sms', 'used'].includes(tanInformation) ? HEADER_STYLE : {paddingTop: '7px', paddingBottom: '7px'};
	const columnContent = generateTanInformationPresentation(tanInformation);
	return (
		<TanColumn>
			<Sticky sx={{top: COLUMN_STICKY_TOP}} style={style}>
				{columnContent}
			</Sticky>
		</TanColumn>
	);
}

export function generateTanInformationPresentation(tanInformation) {
	if (tanInformation === 'sms') {
		return <SynFormattedMessage message='SmsTan' />;
	} else if (tanInformation === 'used') {
		return <SynFormattedMessage message='TanUsed' />;
	}
	return (
		<React.Fragment>
			<MaskedPassword length={5} />
			<TanInformationDialogButton tanInformation={tanInformation.substring('tan:'.length)} />
		</React.Fragment>
	);
}

export function renderDocuments(documents) {
	return (
		<UnorderedList sx={{wordBreak: 'break-all', overflow: 'hidden'}}>
			{documents.map(document => {
				const id = document.get('id');
				const description = document.get('document_description');
				const containedTypes = document.get('dicom_all_modalities') || document.get('generic_all_extensions');
				const createdWhen = document.get('document_created_when');
				return (
					<UnorderedListItem key={id} sx={{py: '3px'}}>
						<DocumentSummary description={description}
											  containedTypes={containedTypes} createdWhen={createdWhen} />
					</UnorderedListItem>
				);
			})}
		</UnorderedList>
	);
}
