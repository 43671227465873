import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import {Body1, H6} from '../../../webview/ui/components/typography/Typography.js';
import DocPermitReceiverForm from './DocPermitReceiverForm.js';
import StepBody from './StepBody.js';

export default function StepReceiverInfo(props) {
	const {nextStep} = props;
	return (
		<StepBody>
			<H6>
				<SynFormattedMessage message='PermitReceiverTitle' />
			</H6>
			<Body1>
				<SynFormattedMessage message='PermitReceiverEMailDescription' />
			</Body1>
			<DocPermitReceiverForm onSubmit={nextStep} />
		</StepBody>
	);
}
StepReceiverInfo.propTypes = {
	nextStep: PropTypes.func
};
