import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../../webview/commons/containers/FormContainer.js';
import EMailFormField from '../../../webview/material-design/components/form/EMailFormField.js';
import PermitReceiverFormAccessors from '../DocPermitReceiverFormAccessors.js';

export default function DocPermitReceiverForm(props) {
	const {onSubmit} = props;
	return (
		<FormContainer description={PermitReceiverFormAccessors} onSubmit={onSubmit}
							initialValuesSelector={PermitReceiverFormAccessors.getAllMappedFormFieldValues}>
			<EMailFormField fieldName='email' />
		</FormContainer>
	);
}
DocPermitReceiverForm.propTypes = {
	onSubmit: PropTypes.func
};
