import {Record} from 'immutable';

import search from '../../webview/commons/api/operations/search';

const UPLOAD_ORGUNIT_URL = '/upload/producers';

const UploadOrgunit = new Record({
	orgunitAbk: null,
	orgunitName: ''
}, 'UploadOrgunitEntry');

export function processRawUploadOrgunitResults(rawResults) {
	const {results} = rawResults;
	rawResults.results = results.map(producer => new UploadOrgunit({
		orgunitAbk: producer.abk,
		orgunitName: producer.name
	}));
	return rawResults;
}

export function searchUploadOrgunitApi() {
	return search(UPLOAD_ORGUNIT_URL, {}, processRawUploadOrgunitResults);
}
