import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import GuardedPatientInfo from '../../webview/patient-details/components/GuardedPatientInfo.js';
import {selectSessionPatientContext} from '../flux/webPatientSessionSelectors.js';

export default connect(
	createStructuredSelector({
		patient: selectSessionPatientContext
	}),
	undefined,
	stateProps => {
		const {patient} = stateProps;
		return {
			...stateProps,
			canLoad: true,
			hasLoadError: !patient
		};
	}
)(GuardedPatientInfo);
