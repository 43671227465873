import passwordStateReducer from '../../webview/authentication/flux/reducers/PasswordStateReducer.js';
import basicApplicationFlux from '../../webview/commons/flux/BasicApplicationFlux.js';
import aimLinkReducer from '../../webview/commons/reducers/AIMLinkReducer.js';
import crudResourcesReducer from '../../webview/commons/reducers/crudresources/CrudResourcesReducer.js';
import selectedItemsReducer from '../../webview/commons/reducers/SelectedItemsReducer.js';
import structuredDataReducer from '../../webview/commons/reducers/StructuredDataReducer.js';
import patientDetailsReducer from '../../webview/patient-details/flux/PatientDetailsReducer.js';
import UIReducer from '../../webview/ui/flux/UIReducer.js';
import annotationReducer from '../../webview/viewer/flux/reducers/AnnotationReducer.js';
import viewerItemsReducer from '../../webview/viewer/flux/reducers/ViewerItemsReducer.js';
import viewersReducer from '../../webview/viewer/flux/reducers/ViewerReducer.js';
import viewerSyncToolsReducer from '../../webview/viewer/synchronization/flux/viewerSyncReducer.js';
import ALLOWED_METRICS from '../AllowedMetrics.js';
import WebPatientRoutes from '../WebPatientRoutes.js';
import webPatientSaga from './webPatientSaga.js';
import webPatientSessionReducer from './webPatientSessionReducer.js';

export default function createWebPatientFlux() {
	return basicApplicationFlux(WebPatientRoutes, {
		ui: UIReducer,
		session: webPatientSessionReducer,
		passwordState: passwordStateReducer,
		structuredData: structuredDataReducer,
		crudResources: crudResourcesReducer,
		patientDetails: patientDetailsReducer,
		viewers: viewersReducer,
		viewerSyncTools: viewerSyncToolsReducer,
		annotations: annotationReducer,
		aimLinks: aimLinkReducer,
		viewerItems: viewerItemsReducer,
		selectedItems: selectedItemsReducer
	}, webPatientSaga, ALLOWED_METRICS);
}
