import {EXPORT_DOCUMENTS_PAGE_NAME} from '../../constants/PageNames.js';
import WebPatientAppBar from '../WebPatientAppBar.js';
import WebPatientExportDocumentsPageBody from '../WebPatientExportDocumentsPageBody.js';
import createWebPatientPage from './createWebPatientPage.js';

const WebPatientExportDocumentsPage = createWebPatientPage(
	WebPatientAppBar, WebPatientExportDocumentsPageBody, EXPORT_DOCUMENTS_PAGE_NAME
);

export default WebPatientExportDocumentsPage;
