import React from 'react';

import useBrick from '../../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../../webview/commons/bricks/DeviceInfo.js';
import ListContainer from '../../../../webview/ui/components/List/ListContainer.js';
import ListRow from '../../../../webview/ui/components/List/ListRow.js';
import DocumentPermitService from '../../bricks/DocumentPermitService.js';
import DesktopPermitsListEntry from './DesktopPermitsListEntry.js';
import MobilePermitsListEntry from './MobilePermitsListEntry.js';

export default function DocumentPermitsListBody() {
	const documentPermits = useBrick(DocumentPermitService, service => service.getPermits());
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const EntryElement = isMobileDevice ? MobilePermitsListEntry : DesktopPermitsListEntry;
	return (
		<ListContainer className='table-body'>
			{documentPermits.map(permit => (
				<ListRow key={permit.get('id')}>
					<EntryElement entry={permit} />
				</ListRow>
			)).toJS()}
		</ListContainer>
	);
}
