import {createWithStructuredResponse} from '../../../webview/commons/api/operations/create.js';
import {destroy, search} from '../../../webview/commons/api/operations/index.js';
import {extractLongDate} from '../../../webview/commons/utils/DateUtils';

const PERMIT_API_BASE_URL = '/documentpermit';

/**
 * Tries to create a new document permit to the provided document ids.
 * The server will also send a notification email to the address in parameter email.
 *
 * @param email - EMail address of the notification receiver.
 * @param documentIds - aimids of the documents to include in the permit.
 * @return {Promise<String>} - a promise for the tan to claim the created permit.
 */
export function createPermit(email, ...documentIds) {
	const permittedDocuments = documentIds.map(id => ({id}));
	const createPayload = {
		email,
		documents: permittedDocuments
	};
	return createWithStructuredResponse(PERMIT_API_BASE_URL, createPayload);
}

/**
 * Fetches all permits, created by the currently logged on user.
 *
 * @return {Promise<ResultList>} - the result list containing all permits
 */
export async function searchAllPermits() {
	const permits = await search(`${PERMIT_API_BASE_URL}.json`, {nocount: true});
	return permits.map(permit => permit
		.update('valid_until', extractLongDate)
		.update('documents', documents => documents.map(
			document => document.update('document_created_when', extractLongDate)
		))
	);
}

/**
 * Deletes all the permits in the provided list as long as the currently logged on user is authorized to.
 *
 * @param permitIds - aim ids of the permits to be deleted.
 * @return {Promise<Array<Response|Error>>} - Responses or Errors of all delete requests.
 */
export function deletePermits(...permitIds) {
	const allIds = permitIds
		.filter(id => id !== null && id !== undefined)
		.map(id => (typeof id === 'string' ? id : `${id}`))
		.map(id => id.trim())
		.filter(id => id !== '')
		.join(';');
	const deleteUrl = `${PERMIT_API_BASE_URL}/${encodeURIComponent(allIds)}`;
	return destroy(deleteUrl);
}
