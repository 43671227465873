import React from 'react';
import {styled} from '@mui/material/styles';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import StatusMessage from '../../../webview/commons/components/webview/StatusMessage.js';
import {STATUS_FAILED, STATUS_SUCCESS} from '../../../webview/commons/constants/StatusMessageConstants.js';
import SelectionPurgerContainer from '../../../webview/commons/containers/SelectionPurgerContainer';
import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import PrimaryItemText from '../../../webview/material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../../webview/material-design/components/text/SecondaryItemText.js';
import CopyToClipboardButton from '../../../webview/ui/components/CopyToClipboardButton.js';
import Spacer from '../../../webview/ui/components/layout/Spacer.js';
import {Body1} from '../../../webview/ui/components/typography/Typography.js';
import CreateDocPermitWorkflow from '../bricks/CreateDocPermitWorkflow.js';

const StyledTable = styled('table')(styleProps => {
	const {theme: {spacing}} = styleProps;
	return {
		padding: `${spacing(2)} 0`,
		tr: {
			'td:nth-child(1)': {
				textAlign: 'right',
				paddingRight: spacing(2)
			},
			'td:nth-child(2)': {
				textAlign: 'left',
				paddingLeft: spacing(2)
			}
		}
	};
});

export default function PermitCreationResultDisplay() {
	const hasSucceeded = useBrick(CreateDocPermitWorkflow, workflow => workflow.hasSucceeded());
	return hasSucceeded
		? <PermitCreationSummary />
		: <PermitCreationError />;
}

function PermitCreationSummary() {
	const {email, accessCode} = useBrick(CreateDocPermitWorkflow, workflow => ({
		email: workflow.getReceiverEMail(),
		accessCode: workflow.getAccessCode()
	}));
	return (
		<React.Fragment>
			<StatusMessage status={STATUS_SUCCESS}>
				<SynFormattedMessage message='ShortPermitCreationMessage' />
			</StatusMessage>
			<StyledTable>
				<tbody>
					<tr>
						<td>
							<SecondaryItemText>
								<SynFormattedMessage message='PermitReceiverTitle' />
							</SecondaryItemText>
						</td>
						<td>
							<PrimaryItemText>
								{email}
							</PrimaryItemText>
						</td>
					</tr>
					<tr>
						<td>
							<SecondaryItemText>
								<SynFormattedMessage message='PermitAccessCodeTitle' />
							</SecondaryItemText>
						</td>
						<td>
							{accessCode}
							<CopyToClipboardButton value={accessCode} />
						</td>
					</tr>
				</tbody>
			</StyledTable>
			<Body1>
				<SynFormattedMessage message='AccessCodeCommunicationNote' />
			</Body1>
			<Spacer />
			<SelectionPurgerContainer />
		</React.Fragment>
	);
}

function PermitCreationError() {
	return (
		<StatusMessage status={STATUS_FAILED}>
			<SynFormattedMessage message='ShortPermitCreationFailedMessage' />
		</StatusMessage>
	);
}
