import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import PopupHelpButton from '../../../webview/commons/components/webview/PopupHelpButton.js';
import UserContextGuard from '../../../webview/commons/containers/UserContextGuard.js';
import PatientDetailsBackLinkContainer
	from '../../../webview/patient-details/flux/containers/PatientDetailsBackLinkContainer.js';
import PatientDetailsPageBodyContainer
	from '../../../webview/patient-details/flux/containers/PatientDetailsPageBodyContainer.js';
import {hasMatchingPatientDetails} from '../../../webview/patient-details/flux/selectors/PatientDetailsSelectors.js';
import BackIcon from '../../../webview/ui/components/icons/BackIcon.js';
import {uiIsMobileDevice} from '../../../webview/ui/flux/UISelectors.js';
import {WebPatientPopupHelp} from '../../../webview/viewer/components/ViewerPopupHelp.js';
import {isViewerShown} from '../../../webview/viewer/flux/selectors/ViewerSelectors.js';
import {MY_EXAMINATIONS_PAGE_NAME} from '../../constants/PageNames.js';
import PatientAppBar from '../PatientAppBar.js';
import WebPatientDetailsLoadError from '../WebPatientDetailsLoadError.js';
import createWebPatientPage from './createWebPatientPage.js';

import '../../../styles/patient-details/components/PatientDetailsAppBar.scss';

class MyExaminationsBody extends React.PureComponent {
	render() {
		return (
			<UserContextGuard>
				<PatientDetailsPageBodyContainer errorComponent={WebPatientDetailsLoadError} {...this.props} />
			</UserContextGuard>
		);
	}
}

class MyExaminationsAppBar extends React.PureComponent {
	render() {
		const {sidebarElevated, viewerVisible, isMobileDevice, hasPatientDetailsData} = this.props;
		const shouldIncludeHelpButton = !(isMobileDevice && !viewerVisible) && hasPatientDetailsData;
		let menuButton;
		if (!sidebarElevated) {
			menuButton = false;
		} else if (isMobileDevice && viewerVisible) {
			menuButton = (
				<PatientDetailsBackLinkContainer>
					<BackIcon />
				</PatientDetailsBackLinkContainer>
			);
		}
		return (
			<PatientAppBar sidebarElevated={sidebarElevated} menuButton={menuButton}>
				{shouldIncludeHelpButton &&
					<PopupHelpButton helpComponent={WebPatientPopupHelp} />
				}
			</PatientAppBar>
		);
	}
}

MyExaminationsAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired,
	viewerVisible: PropTypes.bool.isRequired,
	isMobileDevice: PropTypes.bool.isRequired,
	hasPatientDetailsData: PropTypes.bool.isRequired
};

export default createWebPatientPage(
	connect(
		state => ({
			viewerVisible: isViewerShown(state),
			isMobileDevice: uiIsMobileDevice(state),
			hasPatientDetailsData: hasMatchingPatientDetails(state)
		})
	)(MyExaminationsAppBar),
	MyExaminationsBody,
	MY_EXAMINATIONS_PAGE_NAME
);
