import React from 'react';
import {useStore} from 'react-redux';

import PatientContextMonitor from '../../webupload/bricks/PatientContextMonitor.js';
import UploadFilesBrowserController from '../../webupload/bricks/UploadFilesBrowserController.js';
import UploadFilesCollection from '../../webupload/bricks/UploadFilesCollection.js';
import UploadFilesCollector from '../../webupload/bricks/UploadFilesCollector';
import UploadWizardTranslator from '../../webupload/i18n/UploadWizardTranslator.js';
import AutoBrickFactory from '../../webview/bricks/components/AutoBrickFactory.js';
import {
	ALLOWED_UPLOAD_FILE_EXTENSIONS,
	ALLOWED_UPLOAD_FILE_FILE_SIZE
} from '../../webview/commons/constants/SynSettingsConstants.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks';
import {TranslationProvider} from '../../webview/i18n/components/TranslationContext.js';
import {scheduleTask} from '../../webview/scheduling/api';
import uploadFile from '../api/uploadFile.js';
import {searchUploadOrgunitApi} from '../api/UploadOrgunitApi.js';
import SessionPatientContext from '../bricks/SessionPatientContext.js';
import WebPatientProducerList from '../bricks/WebPatientProducerList';
import WebPatientUploadContext from '../bricks/WebPatientUploadContext.js';
import WebPatientUploadController from '../bricks/WebPatientUploadController';
import WebPatientUploadWizard from './WebPatientUploadWizard';
import WebPatientUploadWizardSteps from './WebPatientUploadWizardSteps.js';

import '../../styles/upload/UploadPageBody.scss';

export default function WebPatientUploadPageBody() {
	const reduxStore = useStore();
	const sessionPatientContextFactory = useMemoFactory(createSessionPatientContextFactory, reduxStore);
	return (
		<React.Fragment>
			<AutoBrickFactory brickType={UploadFilesCollection} />
			<AutoBrickFactory brickType={UploadFilesCollector}
					brickFactory={createUploadFilesCollector} additionalDependencies={[UploadFilesCollection]} />
			<AutoBrickFactory brickType={SessionPatientContext} brickFactory={sessionPatientContextFactory} />
			<AutoBrickFactory brickType={PatientContextMonitor} />
			<AutoBrickFactory brickType={WebPatientProducerList} brickFactory={createProducerList} />
			<AutoBrickFactory brickType={WebPatientUploadContext} />
			<AutoBrickFactory brickType={WebPatientUploadController} brickFactory={createUploadController} />
			<AutoBrickFactory brickType={UploadFilesBrowserController} />
			<TranslationProvider translator={UploadWizardTranslator}>
				<WebPatientUploadWizard stepsComponent={WebPatientUploadWizardSteps} />
			</TranslationProvider>
		</React.Fragment>
	);
}

function createUploadFilesCollector(uploadFilesCollection) {
	return new UploadFilesCollector(
		ALLOWED_UPLOAD_FILE_EXTENSIONS,
		ALLOWED_UPLOAD_FILE_FILE_SIZE,
		uploadFilesCollection.addFileList, uploadFilesCollection.setPreparedContainerValue
	);
}

function createProducerList() {
	return new WebPatientProducerList(searchUploadOrgunitApi);
}

function createSessionPatientContextFactory(reduxStore) {
	return () => new SessionPatientContext(reduxStore);
}

function createUploadController(...dependencies) {
	return new WebPatientUploadController(...dependencies, scheduleTask, uploadFile);
}
