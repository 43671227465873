import React from 'react';
import PropTypes from 'prop-types';

import StepPerformUpload from '../../webupload/components/StepPerformUpload.js';
import UploadReport from '../../webupload/components/UploadReport.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks/index.js';
import WebPatientUploadController from '../bricks/WebPatientUploadController.js';
import WebPatientUploadStepPrepareFileList from '../WebPatientUploadStepPrepareFileList.js';
import WebPatientStepPrepareUploadProducer from './WebPatientStepPrepareUploadProducer.js';

export default function WebPatientUploadWizardSteps(props) {
	const {controllerState} = props;
	const StepperContentComponent = useMemoFactory(getStepperComponent, controllerState);
	return StepperContentComponent && <StepperContentComponent controllerState={controllerState} {...props} />;
}

WebPatientUploadWizardSteps.propTypes = {
	controllerState: PropTypes.oneOf(
		Object.keys(WebPatientUploadController.State).map(key => WebPatientUploadController.State[key])
	)
};

function getStepperComponent(uploadControllerState) {
	switch (uploadControllerState) {
		case WebPatientUploadController.State.SELECT_PRODUCER:
			return WebPatientStepPrepareUploadProducer;
		case WebPatientUploadController.State.PREPARING:
		case WebPatientUploadController.State.READY:
			return WebPatientUploadStepPrepareFileList;
		case WebPatientUploadController.State.UPLOADING:
			return StepPerformUpload;
		case WebPatientUploadController.State.FINISHED:
			return UploadReport;
		default:
			return null;
	}
}
