import Immutable from 'immutable';
import reduceReducers from 'reduce-reducers';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION} from '../../webview/authentication/constants/SessionActionTypes.js';
import sessionReducer, {INITIAL_STATE} from '../../webview/authentication/flux/reducers/sessionReducer.js';
import {extractLongDate} from '../../webview/commons/utils/DateUtils.js';
import {SESSION_PATIENT_CONTEXT} from '../constants/WebPatientSessionFields.js';

const webPatientSessionReducer = handleActions({
	[BEGIN_SESSION]: handleBeginSession
}, INITIAL_STATE);

function handleBeginSession(state, action) {
	const {payload: {patient}} = action;
	return state
		.set(SESSION_PATIENT_CONTEXT, preparePatientContext(patient));
}

function preparePatientContext(patientPayload) {
	if (patientPayload) {
		const {sex} = patientPayload;
		return Immutable.fromJS(patientPayload)
			.update('birth_date', '', extractLongDate)
			.set('sex_fk', sex)
			.remove('sex');
	}
	return null;
}

export default reduceReducers(
	sessionReducer,
	webPatientSessionReducer
);
