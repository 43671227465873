import PatientContextMonitor from '../../webupload/bricks/PatientContextMonitor';
import UploadController from '../../webupload/bricks/UploadController';
import UploadFilesCollection from '../../webupload/bricks/UploadFilesCollection';
import {UploadContextBuilder} from '../../webview/archive-documents/api/UploadContextBuilder.js';
import {declareBrick} from '../../webview/bricks/brickTools';
import {memoizeLast} from '../../webview/commons/utils/FunctionUtils';
import WebPatientProducerList from './WebPatientProducerList';
import WebPatientUploadContext from './WebPatientUploadContext';

export default class WebPatientUploadController extends UploadController {
	constructor(
		webPatientUploadContext, uploadFilesCollection, patientContextMonitor, webPatientProducerList,
		scheduleUploadTask, uploadFileApiCall
	) {
		super(webPatientUploadContext, uploadFilesCollection, patientContextMonitor, scheduleUploadTask);
		this.uploadFileCall = uploadFileApiCall;
		this.webPatientProducerList = webPatientProducerList;

		const memoizedProducerChanged = memoizeLast(this.onProducerChanged);
		this.subscribeTo(
			webPatientUploadContext,
			uploadContext => memoizedProducerChanged(uploadContext.getProducerAbk())
		);
	}

	onProducerChanged(producerAbk) {
		this.updateBrickState(oldState => ({
			...oldState,
			producerAbk
		}));
	}

	uploadFile(fileName, file, uploadStartedWhen) {
		const contextBuilder = new UploadContextBuilder()
			.setProducerAbk(this.#getProducer())
			.setGenericDocumentCreatedWhen(uploadStartedWhen);
		return this.uploadFileCall(fileName, file, contextBuilder.build());
	}

	setStateSelectProducer() {
		const {getSingleProducer} = this.webPatientProducerList;
		if (getSingleProducer()) {
			return this.startUpload();
		}
		return this.updateBrickState(oldState => ({
			...oldState,
			state: oldState.state === WebPatientUploadController.State.READY
				? WebPatientUploadController.State.SELECT_PRODUCER
				: oldState.state
		}));
	}

	setStateReady() {
		return this.updateBrickState(oldState => ({
			...oldState,
			state: oldState.state === WebPatientUploadController.State.SELECT_PRODUCER
				? WebPatientUploadController.State.READY
				: oldState.state
		}));
	}

	#getProducer() {
		return this.getBrickState().producerAbk;
	}
}


WebPatientUploadController.State = {
	...UploadController.State,
	SELECT_PRODUCER: Symbol('SelectProducer')
};
declareBrick(
	WebPatientUploadController,
	[WebPatientUploadContext, UploadFilesCollection, PatientContextMonitor, WebPatientProducerList]
);
