import BrickBase from '../../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../../webview/bricks/brickTools.js';
import {EMAIL_SYNTAX_REGEX} from '../../../webview/commons/data/validators/emailValidator.js';
import {noop} from '../../../webview/commons/utils/FunctionUtils.js';

const QUERY_RECEIVER_STATE = Symbol('QUERY_RECEIVER');
const INITIAL_STATE = {
	receiverEMail: '',
	error: null,
	state: QUERY_RECEIVER_STATE
};

/**
 * This brick models the workflow of creating permits for the passed document ids.
 * It requires receiver info before it can actually create the permit.
 * Once it reached cancelled or finished state it can no longer be updated and is practically useless.
 *
 * @author p.spitzlinger@synedra.com
 */
export default class CreateDocPermitWorkflow extends BrickBase {
	#documentIds = [];
	#createPermitOperation = noop;

	constructor(documentIds, createPermitOperation) {
		super(INITIAL_STATE);
		this.#documentIds = documentIds;
		this.#createPermitOperation = createPermitOperation;
	}

	canCreatePermit() {
		return this.#hasState(CreateDocPermitWorkflow.STATES.READY);
	}

	isBusy() {
		return this.#hasState(CreateDocPermitWorkflow.STATES.CREATING);
	}

	hasFinished() {
		return this.#hasState(CreateDocPermitWorkflow.STATES.FINISHED) ||
			this.#hasState(CreateDocPermitWorkflow.STATES.CANCELLED);
	}

	wasCancelled() {
		return this.#hasState(CreateDocPermitWorkflow.STATES.CANCELLED);
	}

	hasSucceeded() {
		const {error} = this.getBrickState();
		return this.#hasState(CreateDocPermitWorkflow.STATES.FINISHED) && !error;
	}

	getReceiverEMail() {
		const {receiverEMail} = this.getBrickState();
		return receiverEMail;
	}

	getAccessCode() {
		const {accessCode} = this.getBrickState();
		return accessCode;
	}

	setReceiver(email) {
		this.updateBrickState(oldState => {
			const nextWorkflowState = EMAIL_SYNTAX_REGEX.test(email)
				? CreateDocPermitWorkflow.STATES.READY
				: CreateDocPermitWorkflow.STATES.QUERY_RECEIVER;
			return {
				...oldState,
				receiverEMail: email,
				state: nextWorkflowState
			};
		});
	}

	cancel() {
		this.updateBrickState(({
			...INITIAL_STATE,
			state: CreateDocPermitWorkflow.STATES.CANCELLED
		}));
	}

	async createPermit() {
		if (this.canCreatePermit()) {
			this.updateBrickState({
				error: null,
				state: CreateDocPermitWorkflow.STATES.CREATING
			});
			const partialState = {
				state: CreateDocPermitWorkflow.STATES.FINISHED
			};
			try {
				const email = this.getReceiverEMail();
				const {tan} = await this.#createPermitOperation(email, ...this.#documentIds);
				partialState.accessCode = tan;
			} catch (error) {
				partialState.error = error;
			}
			this.updateBrickState(partialState);
		}
	}

	updateBrickState(updater, ...updaterArgs) {
		if (!this.hasFinished()) {
			super.updateBrickState(updater, ...updaterArgs);
		}
	}

	#hasState(queriedState) {
		const {state} = this.getBrickState();
		return state === queriedState;
	}

	static STATES = {
		QUERY_RECEIVER: QUERY_RECEIVER_STATE,
		READY: Symbol('READY'),
		CREATING: Symbol('CREATING'),
		FINISHED: Symbol('FINISHED'),
		CANCELLED: Symbol('CANCELLED')
	};
}
declareBrick(CreateDocPermitWorkflow);
