import Immutable from 'immutable';

import BrickBase from '../../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../../webview/bricks/brickTools.js';
import {memoizeLast} from '../../../webview/commons/utils/FunctionUtils.js';
import {cancellable} from '../../../webview/commons/utils/PromiseUtils.js';

const MARK_FOR_DELETION_PROPERTY = 'markForDeletion';

const INITIAL_BRICK_STATE = {
	permits: null,
	pendingRequest: null,
	markedForDeletion: Immutable.Set(),
	loadError: null
};

export default class DocumentPermitService extends BrickBase {
	#searchPermits = null;
	#getMemoizedValSeq = null;
	#deletePermits;

	constructor(searchPermits, deletePermits) {
		super(INITIAL_BRICK_STATE);
		this.#searchPermits = searchPermits;
		this.#deletePermits = deletePermits;
		this.#getMemoizedValSeq = memoizeLast(getValSequence);
	}

	static #processSearchResultList(searchResults) {
		return searchResults.getList().reduce(
			(prev, next) => prev.set(next.get('id'), next
				.set(MARK_FOR_DELETION_PROPERTY, false)
			),
			Immutable.OrderedMap()
		);
	}

	loadAllPermits() {
		const {pendingRequest} = this.getBrickState();
		if (pendingRequest) {
			pendingRequest.cancel();
		}

		const newPendingRequest = cancellable(this.#searchPermits());
		newPendingRequest.maybe(searchResults => {
			this.updateBrickState(oldState => {
				if (newPendingRequest === oldState.pendingRequest) {
					return {
						...oldState,
						permits: DocumentPermitService.#processSearchResultList(searchResults)
					};
				}
				return oldState;
			});
		})
			.catch(error => {
				this.updateBrickState(oldState => ({
					...oldState,
					loadError: error
				}));
			})
			.finally(() => {
				this.updateBrickState(oldState => ({
					...oldState,
					pendingRequest: null
				}));
			});

		this.updateBrickState({
			loadError: null,
			pendingRequest: newPendingRequest
		});
	}

	deletePermits(...permitIds) {
		this.#markPermitForDeletion(...permitIds);
		this.#deletePermits(...permitIds)
			.then(() => {
				this.#removePermits(...permitIds);
				this.#unmarkPermitForDeletion(...permitIds);
			})
			.catch(() => {
				this.#unmarkPermitForDeletion(...permitIds);
			});
	}

	#removePermits(...permitIds) {
		this.updateBrickState(oldState => ({
			...oldState,
			permits: oldState.permits.filterNot((ignored, id) => permitIds.includes(id))
		}));
	}

	#markPermitForDeletion(...permitIds) {
		this.updateBrickState(oldState => ({
			...oldState,
			markedForDeletion: oldState.markedForDeletion.union(permitIds)
		}));
	}

	#unmarkPermitForDeletion(...permitIds) {
		this.updateBrickState(oldState => ({
			...oldState,
			markedForDeletion: oldState.markedForDeletion.subtract(permitIds)
		}));
	}

	isMarkedForDeletion(permitId) {
		const {markedForDeletion} = this.getBrickState();
		return markedForDeletion.has(permitId);
	}

	isBusy() {
		return this.#hasPendingRequest();
	}

	getPermits() {
		const {permits} = this.getBrickState();
		return permits ? this.#getMemoizedValSeq(permits) : null;
	}

	getLastLoadError() {
		return this.getBrickState().loadError;
	}

	#hasPendingRequest() {
		const {pendingRequest} = this.getBrickState();
		return Boolean(pendingRequest);
	}
}
declareBrick(DocumentPermitService);

function getValSequence(map) {
	return map.valueSeq();
}
