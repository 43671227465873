import DeviceSizes from '../../../constants/MaterialDeviceSizes.json';
import {
	DEVICE_SIZE_EXTRA_EXTRA_LARGE,
	DEVICE_SIZE_EXTRA_SMALL, DEVICE_SIZE_LARGE,
	DEVICE_SIZE_MEDIUM,
	DEVICE_SIZE_SMALL
} from '../../commons/constants/DeviceInformation.js';

export const DEVICE_MEDIA_QUERY_UP_TO_MOBILE_EXTRA_SMALL = screenSizeUpTo(DEVICE_SIZE_EXTRA_SMALL);
export const DEVICE_MEDIA_QUERY_MOBILE_SMALL = screenSizeBetween(DEVICE_SIZE_EXTRA_SMALL, DEVICE_SIZE_SMALL);
export const DEVICE_MEDIA_QUERY_UP_TO_MOBILE_SMALL = screenSizeUpTo(DEVICE_SIZE_SMALL);
export const DEVICE_MEDIA_QUERY_MOBILE = screenSizeBetween(DEVICE_SIZE_EXTRA_SMALL, DEVICE_SIZE_MEDIUM);
export const DEVICE_MEDIA_QUERY_UP_TO_MOBILE = screenSizeUpTo(DEVICE_SIZE_MEDIUM);
export const DEVICE_MEDIA_QUERY_DESKTOP = screenSizeBetween(DEVICE_SIZE_LARGE, DEVICE_SIZE_EXTRA_EXTRA_LARGE);


function screenSizeBetween(smallDevice, largeDevice) {
	return screenAnd(sizeAtLeast(smallDevice), sizeUpTo(largeDevice));
}

function screenSizeUpTo(device) {
	return screenAnd(sizeUpTo(device));
}

function screenAnd(...conditions) {
	const allConditions = conditions
		.filter(condition => condition.trim() !== '')
		.join(' and ');
	return allConditions.length > 0 ? `@media screen and ${allConditions}` : '';
}

function sizeUpTo(device) {
	const deviceBoundaries = DeviceSizes[device] || {};
	const {'max-width': deviceMaxWidth} = deviceBoundaries;
	return deviceMaxWidth === undefined ? '' : `(max-width: ${deviceMaxWidth}px)`;
}

function sizeAtLeast(device) {
	const deviceBoundaries = DeviceSizes[device] || {};
	const {'min-width': deviceMinWidth} = deviceBoundaries;
	return deviceMinWidth === undefined ? '' : `(min-width: ${deviceMinWidth}px)`;
}
