/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * IMPORTANT:
 *     Keep the set of known metrics in this file in sync with the set of allowed metrics
 *     in WebPatientCoreSC.java
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * @author p.spitzlinger@synedra.com
 */

import {
	ANNOTATION_METRIC_KEYS,
	EXPORT_METRIC_KEYS,
	IMAGE_VIEWER_TOOLS_METRIC_KEYS,
	INFO_METRIC_KEYS,
	LAYOUT_METRIC_KEYS,
	PATIENT_DETAILS_METRIC_KEYS,
	SHOW_HELP_METRIC_KEY,
	VIEWER_TOOLS_METRIC_KEYS,
	WINDOW_PRESET_METRIC_KEYS
} from '../webview/commons/constants/MetricKeys.js';
import AllowedMetricsSet from '../webview/metrics-collector/utils/AllowedMetricsSet.js';

const EXPORT_DOWNLOAD_PATIENT_METRIC_KEY = 'export.DownloadPatient';

const ALLOWED_METRICS = [
	SHOW_HELP_METRIC_KEY,
	EXPORT_DOWNLOAD_PATIENT_METRIC_KEY,
	...EXPORT_METRIC_KEYS,
	...PATIENT_DETAILS_METRIC_KEYS,
	...VIEWER_TOOLS_METRIC_KEYS,
	...IMAGE_VIEWER_TOOLS_METRIC_KEYS,
	...LAYOUT_METRIC_KEYS,
	...ANNOTATION_METRIC_KEYS,
	...WINDOW_PRESET_METRIC_KEYS,
	...INFO_METRIC_KEYS
];

export default new AllowedMetricsSet(ALLOWED_METRICS);
