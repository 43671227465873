import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import {debugError, debugLog} from '../../commons/utils/DebugLog.js';
import {hasClipboardSupport} from '../../commons/utils/FeatureDetectionUtils.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import IconButton from './IconButton.js';
import ContentCopyIcon from './icons/ContentCopyIcon.js';
import Toast from './Toast.js';

const CONTENT_COPY_ICON = <ContentCopyIcon />;

/**
 * Renders a simple IconButton that copies the text-property value to the clipboard when clicked.
 * False is rendered if the browser doesn't support the Clipboard API.
 *
 * @author p.spitzlinger@synedra.com
 */
export default function CopyToClipboardButton(props) {
	const {value, toastMessage} = props;
	const [showToast, setShowToast] = useState(false);
	const doCopyToClipboard = useCopyToClipboardHandler(value, setShowToast);
	const buttonTitle = useTranslation('CopyToClipboard');
	const toastMessageTranslated = useTranslation(toastMessage, {value});
	const handleToastClose = useCallback(() => setShowToast(false), [setShowToast]);
	return (
		hasClipboardSupport() && (
			<React.Fragment>
				<IconButton title={buttonTitle} icon={CONTENT_COPY_ICON} onClick={doCopyToClipboard} />
				<Toast message={toastMessageTranslated} open={showToast} onClose={handleToastClose} />
			</React.Fragment>
		)
	);
}
CopyToClipboardButton.propTypes = {
	value: PropTypes.string,
	toastMessage: PropTypes.string
};

CopyToClipboardButton.defaultProps = {
	toastMessage: 'CopyToClipboardSuccess'
};

function useCopyToClipboardHandler(value, setShowToast) {
	return useCallback(() => {
		copyToClipboard(value, setShowToast);
	}, [value, setShowToast]);
}

function copyToClipboard(value, setShowToast) {
	if (hasClipboardSupport()) {
		navigator.clipboard.writeText(value)
			.then(() => {
				setShowToast(true);
				debugLog(`Copied "${value}" to clipboard.`);
			})
			.catch(error => debugError('Failed to copy text to clipboard', error));
	}
}
