import React from 'react';

import StatusMessage from '../../../../webview/commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../../../webview/commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../../../webview/i18n/components/SynFormattedMessage.js';

export default function PermitsLoadErrorDisplay() {
	return (
		<StatusMessage status={STATUS_FAILED}>
			<SynFormattedMessage message='LoadingOfPermitsFailed' />
		</StatusMessage>
	);
}
