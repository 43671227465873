import React, {useCallback, useState} from 'react';

import DialogAnchor from '../../../../webview/commons/components/DialogAnchor.js';
import useTranslation from '../../../../webview/i18n/hooks/useTranslation.js';
import IconButton from '../../../../webview/ui/components/IconButton.js';
import {VisibilityIcon} from '../../../../webview/ui/components/icons/VisibilityIcon.js';
import {VIEWER_INFO_DIALOG_ID} from '../../../../webview/viewer/constants/ViewerConstants.js';
import TanInformationDialog from './TanInformationDialog.js';

const NEXT_ANCHOR_ID_SYMBOL = Symbol('NEXT_ANCHOR_ID');

export default function TanInformationDialogButton(props) {
	const {tanInformation} = props;
	const [dialogAnchorId] = useState(TanInformationDialogButton.nextAnchorId());
	const [dialogController, setDialogController] = useState();
	const openDialog = useCallback(() => {
		if (dialogController) {
			dialogController.openDialog();
		}
	}, [dialogController]);
	const buttonTitle = useTranslation('ShowTanInformation');
	return (
		<React.Fragment>
			<IconButton title={buttonTitle} icon={<VisibilityIcon />} onClick={openDialog} size='small' />
			<DialogAnchor id={dialogAnchorId} dialogControllerRef={setDialogController}>
				<TanInformationDialog tanInformation={tanInformation} />
			</DialogAnchor>
		</React.Fragment>
	);
}

TanInformationDialogButton.propTypes = TanInformationDialog.propTypes;

TanInformationDialogButton.nextAnchorId = () => {
	const thisId = TanInformationDialogButton[NEXT_ANCHOR_ID_SYMBOL]++;
	return `${VIEWER_INFO_DIALOG_ID}-${thisId}`;
};

TanInformationDialogButton[NEXT_ANCHOR_ID_SYMBOL] = 0;
