import React from 'react';

import {HELP_PATH} from '../../webview/commons/constants/CommonRoutingConstants.js';
import SidebarLinkContainer from '../../webview/commons/containers/SidebarLinkContainer.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import WebPatientMessagesTranslator from '../../webview/i18n/translators/WebPatientMessagesTranslator.js';
import DownloadIcon from '../../webview/ui/components/icons/DownloadIcon.js';
import HelpIcon from '../../webview/ui/components/icons/HelpIcon.js';
import HomeIcon from '../../webview/ui/components/icons/HomeIcon.js';
import ShareIcon from '../../webview/ui/components/icons/ShareIcon.js';
import {UploadRoundedIcon} from '../../webview/ui/components/icons/UploadIcon.js';
import Navigation from '../../webview/ui/components/Navigation.js';
import {
	WEBPATIENT_DOCUMENT_PATH, WEBPATIENT_DOCUMENT_PERMITS_PATH,
	WEBPATIENT_EXPORT_DOCUMENTS_PATH,
	WEBPATIENT_UPLOAD_PATH
} from '../constants/WebPatientRoutingConstants.js';

export default function WebPatientMenu() {
	return (
		<Navigation>
			<SidebarLinkContainer path={WEBPATIENT_DOCUMENT_PATH} icon={<HomeIcon />}>
				<SynFormattedMessage message='MyExaminations' translator={WebPatientMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={WEBPATIENT_DOCUMENT_PERMITS_PATH} icon={<ShareIcon />}>
				<SynFormattedMessage message='Permits' translator={WebPatientMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={WEBPATIENT_UPLOAD_PATH} icon={<UploadRoundedIcon />}>
				<SynFormattedMessage message='UploadNavigationMenuTitle' translator={WebPatientMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={WEBPATIENT_EXPORT_DOCUMENTS_PATH} icon={<DownloadIcon />}>
				<SynFormattedMessage message='ExportDocumentsNavigationMenuTitle' translator={WebPatientMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={HELP_PATH} icon={<HelpIcon />}>
				<SynFormattedMessage message='Help' translator={WebPatientMessagesTranslator} />
			</SidebarLinkContainer>
		</Navigation>
	);
}
