import React from 'react';
import {styled} from '@mui/material/styles';

import VerticalLayout from '../../../webview/ui/components/layout/VerticalLayout.js';

const PADDING_MULTIPLIER = 3;

const StyledVerticalLayout = styled(VerticalLayout)(styleProperties => {
	const {theme} = styleProperties;
	return {
		padding: theme.spacing(PADDING_MULTIPLIER)
	};
});

export default function StepBody(props) {
	const {children} = props;
	return (
		<StyledVerticalLayout justify='center'>
			{children}
		</StyledVerticalLayout>
	);
}
