import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../../../webview/bricks/hooks/useBrick.js';
import {useMemoFactory} from '../../../../webview/commons/utils/customHooks/index.js';
import useTranslation from '../../../../webview/i18n/hooks/useTranslation.js';
import DeleteIcon from '../../../../webview/ui/components/icons/DeleteIcon.js';
import LoadingButton from '../../../../webview/ui/components/LoadingButton.js';
import {COLOR_SECONDARY} from '../../../../webview/ui/constants/SynMUIOptions.js';
import DocumentPermitService from '../../bricks/DocumentPermitService.js';

export default function DeletePermitButton(props) {
	const {permitId} = props;
	const documentPermitServiceSelect = useMemoFactory(createDocumentPermitServiceSelect, permitId);
	const {deletePermits, isDeleting} = useBrick(DocumentPermitService, documentPermitServiceSelect);
	const buttonTitle = useTranslation('DeletePermit');
	const buttonText = useTranslation('Delete');
	const handleClick = useCallback(() => {
		deletePermits(permitId);
	}, [permitId, deletePermits]);
	return (
		<LoadingButton loading={isDeleting} loadingPosition='start' size='small' title={buttonTitle}
		               startIcon={<DeleteIcon />} onClick={handleClick} color={COLOR_SECONDARY} flat opaque>
			{buttonText}
		</LoadingButton>
	);
}

DeletePermitButton.propTypes = {
	permitId: PropTypes.number
};

function createDocumentPermitServiceSelect(permitId) {
	return service => ({
		deletePermits: service.deletePermits,
		isDeleting: service.isMarkedForDeletion(permitId)
	});
}
