import React from 'react';
import PropTypes from 'prop-types';

import UploadWizard from '../../webupload/components/UploadWizard';
import VerticalLayout from '../../webview/ui/components/layout/VerticalLayout.js';
import {WebPatientUploadStepperFooter} from './WebPatientUploadStepperFooter';
import {WebPatientUploadStepperInfo} from './WebPatientUploadStepperInfo';


export default function WebPatientUploadWizard(props) {
	const {stepsComponent} = props;
	return (
		<VerticalLayout justify='start' sx={{overflow: 'auto', height: '100%'}}>
			<UploadWizard stepsComponent={stepsComponent} infoComponent={WebPatientUploadStepperInfo}
			              footerComponent={WebPatientUploadStepperFooter} />
		</VerticalLayout>
	);
}

WebPatientUploadWizard.propTypes = {
	stepsComponent: PropTypes.elementType
};
