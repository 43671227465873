import React from 'react';
import Box from '@mui/material/Box';

const STICKY_STYLES = {
	position: 'sticky',
	top: 0
};

export default function Sticky(props) {
	const {sx: additionalStyles, ...remainingProps} = props;
	const sx = {
		...STICKY_STYLES,
		...additionalStyles
	};
	return <Box sx={sx} {...remainingProps} />;
}

Sticky.propTypes = Box.propTypes;
