import React from 'react';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import DataGuard from '../../../webview/commons/components/data/DataGuard.js';
import CreateDocPermitWorkflow from '../bricks/CreateDocPermitWorkflow.js';
import PermitCreationResultDisplay from './PermitCreationResultDisplay.js';
import StepBody from './StepBody.js';

export default function StepCreatePermit() {
	const isBusy = useBrick(CreateDocPermitWorkflow, workflow => workflow.isBusy());
	return (
		<StepBody>
			<DataGuard data={!isBusy}>
				<PermitCreationResultDisplay />
			</DataGuard>
		</StepBody>
	);
}
