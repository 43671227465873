import React from 'react';

import UploadStepperInfo from '../../webview/commons/components/upload/UploadStepperInfo.js';
import useTranslation from '../../webview/i18n/hooks/useTranslation';
import WebPatientUploadController from '../bricks/WebPatientUploadController';

const STEP_1 = 0;
const STEP_2 = 1;
const STEP_3 = 2;

export function WebPatientUploadStepperInfo() {
	const steps = [
		useTranslation('StepLabelChoseFiles'),
		useTranslation('StepLabelStartUpload'),
		useTranslation('StepLabelConfirm')
	];
	return <UploadStepperInfo stepMap={steps} getStepFromState={getActiveStepWebPatientUpload} />;
}

function getActiveStepWebPatientUpload(uploadControllerState) {
	switch (uploadControllerState) {
		case WebPatientUploadController.State.PREPARING:
		case WebPatientUploadController.State.READY:
			return STEP_1;
		case WebPatientUploadController.State.SELECT_PRODUCER:
		case WebPatientUploadController.State.UPLOADING:
			return STEP_2;
		case WebPatientUploadController.State.FINISHED:
			return STEP_3;
		default:
			return -1;
	}
}


