import React from 'react';
import Box from '@mui/material/Box';

export function UnorderedListItem(props) {
	const {sx: additionalStyles, ...remainingProps} = props;
	const sx = {
		m: 0,
		p: 0,
		...additionalStyles
	};
	return <Box component='li' sx={sx} {...remainingProps} />;
}

UnorderedListItem.propTypes = Box.propTypes;
