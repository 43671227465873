import React from 'react';
import PropTypes from 'prop-types';

import PatientAppBar from './PatientAppBar.js';

export default function WebPatientUploadAppBar(props) {
	const {sidebarElevated} = props;
	const menuButton = sidebarElevated ? undefined : false;
	return <PatientAppBar menuButton={menuButton} sidebarElevated={sidebarElevated} />;
}

WebPatientUploadAppBar.propTypes = {
	sidebarElevated: PropTypes.bool
};
