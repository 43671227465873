import {createSelector} from 'reselect';

import PatientContext from '../../webupload/bricks/PatientContext.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';
import {selectSessionPatientContext} from '../flux/webPatientSessionSelectors.js';

export default class SessionPatientContext extends PatientContext {
	constructor(reduxStore) {
		super();
		const sessionPatientContextSelector =
			createSelector(selectSessionPatientContext, this.loadPatient);
		this.subscribeTo(reduxStore, () => sessionPatientContextSelector(reduxStore.getState()));
	}
}
declareBrick(SessionPatientContext);
