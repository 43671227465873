import createFormAccessors from '../../webview/commons/data/FormAccessorsFactory.js';
import ValidatorsMessagesTranslator from '../../webview/commons/data/validators/i18n/ValidatorsMessagesTranslator.js';
import {
	allFieldsFilledOut,
	email,
	required
} from '../../webview/commons/data/validators/index.js';
import generateUniqueKey from '../../webview/commons/utils/generateUniqueKey.js';
import DocPermitMessagesTranslator from './i18n/DocPermitMessagesTranslator.js';

const PERMIT_RECEIVER_FORM_DATA_ID = generateUniqueKey();

const PERMIT_RECEIVER_FORM_DEFINITIONS = {
	fields: {
		email: {
			label: 'FieldLabelEMailAddress',
			validators: [required, email]
		}
	},
	validators: [allFieldsFilledOut(ValidatorsMessagesTranslator, 'AllFieldsAreRequired')]
};

export default createFormAccessors(
	PERMIT_RECEIVER_FORM_DEFINITIONS, DocPermitMessagesTranslator, PERMIT_RECEIVER_FORM_DATA_ID
);
