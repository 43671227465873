import React from 'react';

import {UPLOAD_PAGE_NAME} from '../../constants/PageNames.js';
import WebPatientUploadAppBarController from '../WebPatientUploadAppBarController.js';
import WebPatientUploadPageBody from '../WebPatientUploadPageBody.js';
import createWebPatientPage from './createWebPatientPage.js';


const WebPatientUploadPage = createWebPatientPage(
	WebPatientUploadAppBarController, WebPatientUploadPageBody, UPLOAD_PAGE_NAME);

export default WebPatientUploadPage;


