import React from 'react';

import ArchiveDocumentsTranslator from '../../webview/archive-documents/i18n/ArchiveDocumentsTranslator';
import useBrick from '../../webview/bricks/hooks/useBrick';
import {UploadInfoForm} from '../../webview/commons/components/upload/UploadInfoForm';
import useTranslation from '../../webview/i18n/hooks/useTranslation';
import ResponsiveCard from '../../webview/ui/components/ResponsiveCard';
import WebPatientProducerList from '../bricks/WebPatientProducerList';
import WebPatientUploadContext from '../bricks/WebPatientUploadContext';

const CARD_STYLE = {
	boxShadow: 'none', 
	flexDirection: 'column',
	padding: '10px', 
	overflowX: 'hidden', 
	overflowY: 'auto'
};

export default function WebPatientStepPrepareUploadProducer(props) {
	return (
		<ResponsiveCard style={CARD_STYLE} growVertically
					maxCardWidth='800px' minCardWidth='700px'>
			<WebPatientProducerForm {...props} />
		</ResponsiveCard>
	);
}

export function WebPatientProducerForm() {
	const archivePermissions = useBrick(WebPatientProducerList, producerList => producerList.getProducers());
	const selectProducer = useBrick(WebPatientUploadContext, uploadContext => uploadContext.selectProducer);
	const currentProducer = useBrick(WebPatientUploadContext, uploadContext => uploadContext.getProducerAbk());

	const orgunitHintMessage = useTranslation('ReceiverHintMessage', undefined, ArchiveDocumentsTranslator);
	const orgunitFieldLabel = useTranslation('Receiver', undefined, ArchiveDocumentsTranslator);
	const orgunitNoErrorMessage = useTranslation('NoReceiverErrorMessage', undefined, ArchiveDocumentsTranslator);

	return (<UploadInfoForm archivePermissions={archivePermissions}
							selectProducer={selectProducer}
							currentProducer={currentProducer} 
							orgunitHintMessage={orgunitHintMessage}
							orgunitFieldLabel={orgunitFieldLabel}
							orgunitNoErrorMessage={orgunitNoErrorMessage} />);
}
