import React from 'react';

import CenterLayout from '../../webview/commons/components/layout/CenterLayout.js';
import SubtextLink from '../../webview/commons/components/SubtextLink.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import {TranslationProvider} from '../../webview/i18n/components/TranslationContext.js';
import WebPatientMessagesTranslator from '../../webview/i18n/translators/WebPatientMessagesTranslator.js';
import PatientDetailsDataGuard from '../../webview/patient-details/flux/containers/PatientDetailsDataGuard.js';
import CardContent from '../../webview/ui/components/Card/CardContent.js';
import ResponsiveCard from '../../webview/ui/components/ResponsiveCard.js';
import {Body1, Body2, H5} from '../../webview/ui/components/typography/Typography.js';
import {TEXT_SECONDARY} from '../../webview/ui/constants/SynMUIOptions.js';
import WebPatientDetailsLoadError from './WebPatientDetailsLoadError.js';
import DownloadAllButton from './WebPatientDownloadAllButton.js';

const WINDOWS_VIEWER_DOWNLOAD_HREF = 'https://download.synedra.com/clientupdate/ViewPersonal?download=true';
const MACOS_VIEWER_DOWNLOAD_HREF = 'https://download.synedra.com/clientupdate/ViewPersonal?download=true&platform=osx';

const NO_DOCS_MESSAGE_ELEMENT = (
	<CenterLayout element='Main'>
		<SynFormattedMessage element={Body1} translator={WebPatientMessagesTranslator} message='NoExportableStudiesAvailable' />
	</CenterLayout>
);

const CARD_CONTENT_STYLE = {
	height: 'calc(100% - 32px)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'start'
};

export default function WebPatientExportDocumentsPageBody() {
	return (
		<PatientDetailsDataGuard errorComponent={WebPatientDetailsLoadError}
		                         noDocsMessageElement={NO_DOCS_MESSAGE_ELEMENT}>
			<TranslationProvider translator={WebPatientMessagesTranslator}>
				<ResponsiveCard centered maxCardWidth='650px' minCardWidth='550px' className='webpatient-export-documents' sx={{height: 400}}>
					<CardContent sx={CARD_CONTENT_STYLE}>
						<SynFormattedMessage element={H5} message='DownloadMyDocumentsHeading' />
						<DownloadAllButton />
						<Body2 color={TEXT_SECONDARY} sx={{marginTop: 'auto'}}>
							<SynFormattedMessage message='DicomViewerHint' />
							<SubtextLink href={WINDOWS_VIEWER_DOWNLOAD_HREF}>
								<SynFormattedMessage message='DicomViewerHintLinkText' messageParams={{platform: 'Windows'}} />
							</SubtextLink>
							{', '}
							<SubtextLink href={MACOS_VIEWER_DOWNLOAD_HREF}>
								<SynFormattedMessage message='DicomViewerHintLinkText' messageParams={{platform: 'macOS'}} />
							</SubtextLink>
						</Body2>
					</CardContent>
				</ResponsiveCard>
			</TranslationProvider>
		</PatientDetailsDataGuard>
	);
}
