import React from 'react';

import {
	DateHead,
	DeleteHead,
	EmailHead,
	NameHead,
	TanHead
} from '../../../../webupload/components/UploadFilesList/Columns.js';
import SynFormattedMessage from '../../../../webview/i18n/components/SynFormattedMessage.js';
import HorizontalLayout from '../../../../webview/ui/components/layout/HorizontalLayout.js';

export const HEADER_VERTICAL_PADDING = 15;
export const HEADER_STYLE = {
	paddingTop: `${HEADER_VERTICAL_PADDING}px`,
	paddingBottom: `${HEADER_VERTICAL_PADDING}px`
};

export default function DesktopPermitsListHeader() {
	return (
		<HorizontalLayout noGrow justify='start' className='table-header table-row'>
			<NameHead style={HEADER_STYLE}>
				<SynFormattedMessage message='Documents' />
			</NameHead>
			<EmailHead style={HEADER_STYLE}>
				<SynFormattedMessage message='PermitReceiverTitle' />
			</EmailHead>
			<TanHead style={HEADER_STYLE}>
				<SynFormattedMessage message='PermitAccessCodeTitle' />
			</TanHead>
			<DateHead style={HEADER_STYLE}>
				<SynFormattedMessage message='ValidUntil' />
			</DateHead>
			<DeleteHead style={{width: '150px'}} />
		</HorizontalLayout>
	);
}
