import React from 'react';

import StatusMessage from '../../webview/commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../webview/commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import WebPatientMessagesTranslator from '../../webview/i18n/translators/WebPatientMessagesTranslator.js';
import PatientDetailsLoadErrorCard from '../../webview/patient-details/components/PatientDetailsLoadErrorCard.js';

export default function WebPatientDetailsLoadError() {
	return (
		<PatientDetailsLoadErrorCard>
			<StatusMessage status={STATUS_FAILED}>
				<SynFormattedMessage className='login-page--message'
				                     message='PatientDataLoadErrorMessage' translator={WebPatientMessagesTranslator} />
			</StatusMessage>
			<SynFormattedMessage className='login-page--message'
			                     message='PatientDataLoadErrorHint' translator={WebPatientMessagesTranslator} />
		</PatientDetailsLoadErrorCard>
	);
}
