import {StatusCodes} from 'http-status-codes';

import createFileUploadRequest from '../../webview/commons/api/createFileUploadRequest.js';
import {handleUnexpectedResponse, tryParseJson} from '../../webview/commons/utils/AjaxUtils.js';

const UPLOAD_URL = '/upload';

export default function uploadFile(fileName, file, context) {
	return fetch(UPLOAD_URL, createFileUploadRequest(file, fileName, context))
		.then(handleUnexpectedResponse(StatusCodes.OK, 'Failed to upload file.'))
		.then(tryParseJson);
}
