import React from 'react';

import StepPrepareUploadList from '../webupload/components/StepPrepareUploadList';


export default function WebPatientUploadStepPrepareFileList(props) {
	const {children, ...remainingProps} = props;
	return (
		<StepPrepareUploadList {...remainingProps}>
			{children}
		</StepPrepareUploadList>
	);
}
