import {all} from 'redux-saga/effects';

import webAppSaga from '../../webview/commons/sagas/webAppSaga.js';
import defaultViewerSyncSaga from '../../webview/viewer/synchronization/flux/defaultViewerSyncSaga.js';

export default function* webPatientSaga() {
	yield all([
		webAppSaga(),
		defaultViewerSyncSaga()
	]);
}
