import React from 'react';

import UploadRestoreTrashButton from '../../webupload/components/UploadRestoreTrashButton.js';
import useBrick from '../../webview/bricks/hooks/useBrick';
import UploadSpinner from '../../webview/commons/components/upload/UploadSpinner.js';
import {UploadStepperFooter} from '../../webview/commons/components/upload/UploadStepperFooter.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks/index';
import WebPatientProducerList from '../bricks/WebPatientProducerList';
import WebPatientUploadContext from '../bricks/WebPatientUploadContext';
import WebPatientUploadController from '../bricks/WebPatientUploadController';


export function WebPatientUploadStepperFooter() {
	const {controllerState, startUpload, setStateSelectProducer, restartController, setStateReady} = useBrick(
		WebPatientUploadController, selectControllerProps
	);
	const currentProducer = useBrick(WebPatientUploadContext, uploadContext => uploadContext.getProducerAbk());
	const onlyOneProducerAvailable = useBrick(WebPatientProducerList, brick => brick.getSingleProducer());
	const hasProducer = useBrick(WebPatientProducerList, brick => brick.hasProducers);
	const {buttonLabel, buttonHandler, buttonLabelBack, buttonHandlerBack} = useMemoFactory(
		getButtonProps, controllerState, startUpload, setStateSelectProducer, currentProducer, onlyOneProducerAvailable,
		restartController, setStateReady
	);
	const isPreparing = [
		WebPatientUploadController.State.PREPARING,
		WebPatientUploadController.State.READY
	].includes(controllerState);
	return (
		hasProducer()
			? (
				<UploadStepperFooter buttonLabel={buttonLabel} buttonHandler={buttonHandler}
			                       buttonLabelBack={buttonLabelBack} buttonHandlerBack={buttonHandlerBack}>
					{isPreparing && <UploadRestoreTrashButton />}
				</UploadStepperFooter>
			)
			: <UploadSpinner />

	);
}

function selectControllerProps(brick) {
	return {
		setStateSelectProducer: brick.setStateSelectProducer,
		startUpload: brick.startUpload,
		controllerState: brick.getState(),
		restartController: brick.restart,
		setStateReady: brick.setStateReady
	};
}

function getButtonProps(
		uploadControllerState, startUpload, setStateSelectProducer, currentProducer, onlyOneProducerAvailable,
		restartController, setStateReady
) {
	const getButtonLabelStartUpload = onlyOneProducerAvailable ? 'StartUpload' : 'ForwardPhrase';
	switch (uploadControllerState) {
		case WebPatientUploadController.State.PREPARING:
			return {
				buttonLabel: getButtonLabelStartUpload
			};
		case WebPatientUploadController.State.READY:
			return {
				buttonLabel: getButtonLabelStartUpload,
				buttonHandler: setStateSelectProducer
			};
		case WebPatientUploadController.State.SELECT_PRODUCER:
			return {
				buttonLabel: 'StartUpload',
				buttonHandler: currentProducer ? startUpload : null,
				buttonLabelBack: 'BackPhrase',
				buttonHandlerBack: setStateReady
			};
		default:
			return {};
	}
}


