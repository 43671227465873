import {DOCUMENT_PERMITS_PAGE_NAME} from '../../constants/PageNames.js';
import WebPatientAppBar from '../WebPatientAppBar.js';
import WebPatientDocumentPermitsPageBody from '../WebPatientDocumentPermitsPageBody.js';
import createWebPatientPage from './createWebPatientPage.js';

const WebPatientDocumentPermitsPage = createWebPatientPage(
	WebPatientAppBar, WebPatientDocumentPermitsPageBody, DOCUMENT_PERMITS_PAGE_NAME
);

export default WebPatientDocumentPermitsPage;
