import Immutable from 'immutable';

import RequestPasswordResetPageContainer from '../webview/authentication/flux/containers/RequestPasswordResetPageContainer.js';
import createPasswordResetRoute from '../webview/commons/components/webview/createPasswordResetRoute.js';
import createUserActivationRoute from '../webview/commons/components/webview/createUserActivationRoute.js';
import {
	HELP_PATH,
	ITEM_DOWNLOAD_PATH,
	ITEM_DOWNLOAD_PATH_PARAMETER_MATCHERS,
	PASSWORDRESET_PATH,
	ROOT_PATH
} from '../webview/commons/constants/CommonRoutingConstants.js';
import {isPasswordResetEnabled} from '../webview/commons/selectors/GeneralConfigSelectors.js';
import createLocation from '../webview/router/createLocation.js';
import {guarded, page, redirectTo, unguarded} from '../webview/router/RoutingUtils.js';
import WebPatientDocumentPermitsPage from './components/pages/DocumentPermitsPage.js';
import MyExaminationsPage from './components/pages/MyExaminationsPage.js';
import WebPatientDownloadPage from './components/pages/WebPatientDownloadPage.js';
import WebPatientExportDocumentsPage from './components/pages/WebPatientExportDocumentsPage.js';
import WebPatientHelpPage from './components/pages/WebPatientHelpPage.js';
import WebPatientUploadPage from './components/pages/WebPatientUploadPage.js';
import {
	WEBPATIENT_DOCUMENT_PATH, WEBPATIENT_DOCUMENT_PERMITS_PATH,
	WEBPATIENT_EXPORT_DOCUMENTS_PATH,
	WEBPATIENT_UPLOAD_PATH
} from './constants/WebPatientRoutingConstants.js';
import {isDocumentPermitsEnabled, isExportDocumentsEnabled, isUploadEnabled} from './SettingsSelectors.js';

const DOCUMENTS_LOCATION = createLocation({
	path: WEBPATIENT_DOCUMENT_PATH
});

export default Immutable.OrderedMap({
	[ROOT_PATH]: guarded(() => redirectTo(DOCUMENTS_LOCATION)),
	[WEBPATIENT_DOCUMENT_PATH]: guarded(page(MyExaminationsPage)),
	[WEBPATIENT_DOCUMENT_PERMITS_PATH]: guarded(page(WebPatientDocumentPermitsPage), isDocumentPermitsEnabled),
	[WEBPATIENT_UPLOAD_PATH]: guarded(page(WebPatientUploadPage), isUploadEnabled),
	[WEBPATIENT_EXPORT_DOCUMENTS_PATH]: guarded(page(WebPatientExportDocumentsPage), isExportDocumentsEnabled),
	[HELP_PATH]: guarded(page(WebPatientHelpPage)),
	[PASSWORDRESET_PATH]: unguarded(page(RequestPasswordResetPageContainer), isPasswordResetEnabled),
	[ITEM_DOWNLOAD_PATH]: guarded(page(WebPatientDownloadPage),
		undefined, undefined, ITEM_DOWNLOAD_PATH_PARAMETER_MATCHERS)
}).map(createUserActivationRoute)
	.map(createPasswordResetRoute);
