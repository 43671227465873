import {declareBrick} from '../../webview/bricks/brickTools';
import ProducerList from '../../webview/upload/bricks/ProducerList';


export default class WebPatientProducerList extends ProducerList {
	constructor(searchArchivePermissions) {
		super();
		this.fetchApiCall = searchArchivePermissions;
		this.loadResults();
	}

	fetchProducerList() {
		return this.fetchApiCall();
	}
}
declareBrick(WebPatientProducerList);


