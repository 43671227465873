import React, {useMemo} from 'react';

import BrickProvider from '../../webview/bricks/components/BrickProvider.js';
import {TranslationProvider} from '../../webview/i18n/components/TranslationContext.js';
import {deletePermits, searchAllPermits} from '../document-permits/api/documentPermitApi.js';
import DocumentPermitService from '../document-permits/bricks/DocumentPermitService.js';
import PermitsListBody from '../document-permits/components/permits-list/PermitsListBody.js';
import DocumentPermissionMessagesTranslator from '../document-permits/i18n/DocPermitMessagesTranslator.js';

import '../../styles/commons/components/data/DataTable.scss';
import '../../styles/material-design/components/DataTable.scss';

export default function WebPatientDocumentPermitsPageBody() {
	const documentPermitService = useMemo(() => new DocumentPermitService(searchAllPermits, deletePermits), []);
	return (
		<TranslationProvider translator={DocumentPermissionMessagesTranslator}>
			<BrickProvider brick={documentPermitService} />
			<PermitsListBody />
		</TranslationProvider>
	);
}
