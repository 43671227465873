import React from 'react';

import Main from '../../../../webview/a11y/components/landmarks/Main';
import useBrick from '../../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../../webview/commons/bricks/DeviceInfo.js';
import DataGuard from '../../../../webview/commons/components/data/DataGuard.js';
import {useMemoFactory} from '../../../../webview/commons/utils/customHooks/index.js';
import SynFormattedMessage from '../../../../webview/i18n/components/SynFormattedMessage.js';
import CardHeader from '../../../../webview/material-design/components/layout/CardHeader.js';
import Title from '../../../../webview/material-design/components/Title.js';
import CardContent from '../../../../webview/ui/components/Card/CardContent.js';
import VerticalLayout from '../../../../webview/ui/components/layout/VerticalLayout.js';
import ResponsiveCard from '../../../../webview/ui/components/ResponsiveCard.js';
import {Body2} from '../../../../webview/ui/components/typography/Typography.js';
import {TEXT_SECONDARY} from '../../../../webview/ui/constants/SynMUIOptions.js';
import DocumentPermitService from '../../bricks/DocumentPermitService.js';
import EmptyPermitsList from './EmptyPermitsList.js';
import PermitsList from './PermitsList.js';
import PermitsLoadErrorDisplay from './PermitsLoadErrorDisplay.js';

import '../../../../styles/commons/components/data/DataTable.scss';
import '../../../../styles/material-design/components/DataTable.scss';

export default function PermitsListBody() {
	const {triedToLoadOnce, loadFailed, documentPermits, loadAllPermits, shouldLoad} = useBrick(
		DocumentPermitService, selectDocumentPermitServiceProps
	);
	const DisplayComponent = useMemoFactory(chooseDisplayComponent, documentPermits, loadFailed);
	const isSmallDevice = useBrick(DeviceInfo, device => device.isSmallDevice());
	return (
		<ResponsiveCard wrapperElement={Main} growVertically className='data-table material-data-table'>
			<DataGuard data={triedToLoadOnce} load={loadAllPermits} isLoadNeeded={shouldLoad}>
				<CardHeader style={{flexDirection: 'row'}}>
					<SynFormattedMessage element={Title} message='MyPermits' />
				</CardHeader>
				<CardContent component={VerticalLayout} sx={{overflow: 'hidden', px: isSmallDevice ? 0 : undefined}}>
					<DisplayComponent />
					<Body2 color={TEXT_SECONDARY} mt='50px' px={isSmallDevice ? 2 : undefined}>
						<SynFormattedMessage message='PermitsListNote' />
					</Body2>
				</CardContent>
			</DataGuard>
		</ResponsiveCard>
	);
}

function chooseDisplayComponent(documentPermits, loadFailed) {
	const hasPermits = documentPermits && documentPermits.count() > 0;
	let chosenComponent = EmptyPermitsList;
	if (hasPermits) {
		chosenComponent = PermitsList;
	} else if (loadFailed) {
		chosenComponent = PermitsLoadErrorDisplay;
	}
	return chosenComponent;
}

function selectDocumentPermitServiceProps(service) {
	const documentPermits = service.getPermits();
	const loadFailed = service.getLastLoadError() !== null;
	return {
		triedToLoadOnce: documentPermits !== null || loadFailed,
		documentPermits,
		loadAllPermits: service.loadAllPermits,
		shouldLoad: documentPermits === null && !service.isBusy() && !loadFailed,
		loadFailed
	};
}
