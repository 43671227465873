import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {selectUserContextData} from '../../authentication/flux/selectors/SessionSelectors.js';
import DataGuard from '../../authentication/utils/SessionGuard.js';

export default connect(
	createStructuredSelector({
		data: selectUserContextData
	})
)(DataGuard);
