import React from 'react';

import SynFormattedMessage from '../../../../webview/i18n/components/SynFormattedMessage.js';
import HorizontalLayout from '../../../../webview/ui/components/layout/HorizontalLayout.js';
import {HEADER_STYLE} from './DesktopPermitsListHeader.js';

export default function MobilePermitsListHeader() {
	return (
		<HorizontalLayout noGrow justify='start' className='table-header table-row' style={HEADER_STYLE}>
			<SynFormattedMessage className='material-column table-head' message='PermitReceiverTitle' style={{flexGrow: 1}} />
			<SynFormattedMessage className='material-column table-head' message='ValidUntil' />
			<SynFormattedMessage className='material-column table-head' message='PermitAccessCodeTitle' />
		</HorizontalLayout>
	);
}
