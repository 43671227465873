import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import {useAction} from '../../../webview/commons/flux/ReactHooks.js';
import {useMemoFactory} from '../../../webview/commons/utils/customHooks/index.js';
import {memoizeLast} from '../../../webview/commons/utils/FunctionUtils.js';
import {StepperFooterStateBuilder} from '../../../webview/commons/utils/StepperUtils.js';
import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import Stepper from '../../../webview/material-design/components/Stepper.js';
import CreateDocPermitWorkflow from '../bricks/CreateDocPermitWorkflow.js';
import PermitReceiverFormAccessors from '../DocPermitReceiverFormAccessors.js';
import StepCreatePermit from './StepCreatePermit.js';
import StepReceiverInfo from './StepReceiverInfo.js';

const STEPS = [
	StepReceiverInfo,
	StepCreatePermit
];

const CREATE_PERMIT_BUTTON_LABEL = <SynFormattedMessage message='CreatePermitButtonLabel' />;

export default React.memo(CreateDocPermitStepper);

function CreateDocPermitStepper(props) {
	const {onFinished} = props;
	const stepperProps = useStepperProps(onFinished);
	return <Stepper {...stepperProps} />;
}
CreateDocPermitStepper.propTypes = {
	onFinished: PropTypes.func
};

function useStepperProps(onFinished) {
	const forceFormValidation = useAction(PermitReceiverFormAccessors.setFormValidationForced, true);
	const memoizedFooterStateFor = useMemoFactory(memoizeLast, footerStateFor);
	const boundDeriveStepperProps = useCallback(
		workflow => deriveStepperProps(workflow, onFinished, forceFormValidation, memoizedFooterStateFor),
		[onFinished, forceFormValidation, memoizedFooterStateFor]
	);
	return useBrick(CreateDocPermitWorkflow, boundDeriveStepperProps);
}

function deriveStepperProps(workflow, onFinished, forceFormValidation, buildFooterState) {
	const currentStep = deriveStepFromWorkflow(workflow);
	const hasFinished = workflow.hasFinished();
	const wasCancelled = workflow.wasCancelled();
	const isBusy = workflow.isBusy();
	return {
		currentStep,
		previousStep: previousStepFor(workflow),
		nextStep: nextStepFor(workflow, onFinished, forceFormValidation),
		StepComponent: STEPS[currentStep],
		nrSteps: STEPS.length,
		isStepComplete: isCompleteFor(workflow),
		wasCancelled,
		footerState: buildFooterState(!hasFinished && !isBusy)
	};
}

function deriveStepFromWorkflow(workflow) {
	return (workflow.isBusy() || workflow.hasFinished()) ? 1 : 0;
}

function previousStepFor(workflow) {
	return (workflow.isBusy() || workflow.hasFinished()) ? null : workflow.cancel;
}

function nextStepFor(workflow, onFinished, forceFormValidation) {
	if (workflow.canCreatePermit()) {
		return workflow.createPermit;
	} else if (workflow.hasFinished()) {
		return onFinished;
	}
	return forceFormValidation;
}

function isCompleteFor(workflow) {
	return !workflow.isBusy();
}

function footerStateFor(canGoBack) {
	let stateBuilder = new StepperFooterStateBuilder();
	if (canGoBack) {
		stateBuilder = stateBuilder
			.setNextLabel(CREATE_PERMIT_BUTTON_LABEL)
			.previousIsCancel();
	} else {
		stateBuilder = stateBuilder
			.nextIsFinish()
			.hidePreviousButton();
	}
	return stateBuilder.build();
}
