import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../../webview/i18n/components/SynFormattedMessage.js';
import {TranslationProvider} from '../../../../webview/i18n/components/TranslationContext.js';
import CopyToClipboardButton from '../../../../webview/ui/components/CopyToClipboardButton.js';
import HorizontalLayout from '../../../../webview/ui/components/layout/HorizontalLayout.js';
import RevealedPassword from '../../../../webview/ui/components/RevealedPassword.js';
import SimpleDialog from '../../../../webview/ui/components/SimpleDialog.js';
import DocumentPermissionMessagesTranslator from '../../i18n/DocPermitMessagesTranslator.js';

const DIALOG_TITLE = <SynFormattedMessage message='PermitAccessCodeTitle' />;
export default function TanInformationDialog(props) {
	const {tanInformation, ...remainingProps} = props;
	return (
		<TranslationProvider translator={DocumentPermissionMessagesTranslator}>
			<SimpleDialog title={DIALOG_TITLE} {...remainingProps}>
				<HorizontalLayout sx={{minHeight: '230px', minWidth: '250px'}} justify='center' align='center'>
					<RevealedPassword password={tanInformation} p={2} bgcolor='#eee' />
					<CopyToClipboardButton value={tanInformation} />
				</HorizontalLayout>
			</SimpleDialog>
		</TranslationProvider>
	);
}

TanInformationDialog.propTypes = {
	tanInformation: PropTypes.string
};
