import {useStore} from 'react-redux';
import {createSelector} from 'reselect';

import {useEffectEasily, useMemoFactory} from '../../../webview/commons/utils/customHooks';
import {callSafe} from '../../../webview/commons/utils/FunctionUtils.js';
import {createPermit} from '../api/documentPermitApi.js';
import CreateDocPermitWorkflow from '../bricks/CreateDocPermitWorkflow.js';
import PermitReceiverFormAccessors from '../DocPermitReceiverFormAccessors.js';

export default function useWorkflowBrick(documentIds, onClose) {
	const workflowBrick = useMemoFactory(createWorkflowBrick, documentIds);
	useOnCloseWhenCancelled(workflowBrick, onClose);
	useConnectedWithForm(workflowBrick);
	return workflowBrick;
}

function createWorkflowBrick(documentIds) {
	return new CreateDocPermitWorkflow(documentIds, createPermit);
}

function useOnCloseWhenCancelled(workflowBrick, onClose) {
	useEffectEasily(workflow => workflow.subscribe(brick => {
		if (brick.wasCancelled()) {
			callSafe(onClose);
		}
	}), workflowBrick, onClose);
}

function useConnectedWithForm(workflow) {
	const selectValidEMail = useMemoFactory(createValidFieldValueSelector, 'email');
	const store = useStore();
	useEffectEasily(connectStoreWithWorkflow, store, selectValidEMail, workflow);
	useEffectEasily(s => () => clearFormOnUnmount(s), store);
}

function createValidFieldValueSelector(fieldName) {
	const selectIsEMailValid = createSelector(
		PermitReceiverFormAccessors.getFieldValidationErrorSelector(fieldName),
		validationError => validationError === true
	);
	return createSelector(
		selectIsEMailValid,
		state => state,
		(isEMailValid, state) => (isEMailValid ? PermitReceiverFormAccessors.getFormFieldValue(state, fieldName) : '')
	);
}

function connectStoreWithWorkflow(store, selectEMail, workflow) {
	const connection = createSelector(
		selectEMail,
		email => workflow.setReceiver(email)
	);
	return store.subscribe(() => connection(store.getState()));
}

function clearFormOnUnmount(store) {
	store.dispatch(PermitReceiverFormAccessors.reset());
}
