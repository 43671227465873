import React from 'react';
import PropTypes from 'prop-types';

const PASSWORD_CHAR = '\u{25CF}';

export default function MaskedPassword(props) {
	const {length} = props;
	return PASSWORD_CHAR.repeat(length);
}

MaskedPassword.propTypes = {
	length: PropTypes.number
};
